.Card {
    display: flex;
    margin: 1em 1em;
    flex: 1;
    border-radius: 10px;
}

.Card__link {
    display: flex;
    flex-flow: column;
    width: 100%;
    box-shadow: 0 2px 5px rgba(12, 18, 29, 0.5);
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    transition: 0.2s ease-out;
    background: var(--contrast);
    border: 5px solid var(--contrast);
    color : var(--primary);
    filter: saturate(1);
}
.Card__link:hover{
    box-shadow: 0 10px 10px rgba(12, 18, 29, 0.3);
    transition: 0.1s ease-out;
    transform: translate(0px,-5px);
    filter: saturate(1.4);
}

.Card__backgroundText{
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    overflow: hidden;
    z-index:-1;
    transform: rotate(45deg);
}

.Card__pic-wrap {
    position: relative;
    width: 100%;
    padding-top: 67%;
    overflow: hidden;
}

.Card__img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    object-fit: cover;
    transition: all 0.1s linear;
}

.Card__info {
    padding: 20px 30px;
}
  
.Card__text {
    font-size: 18px;
    line-height: 24px;
}

@media only screen and (max-width: 1024px) {
    .Card {
        margin-bottom: 2rem;
    }
}
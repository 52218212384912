:root{
    --primary: rgb(255, 255, 255);
    --contrast: rgb(0, 0, 0);
    --highlight: rgb(255, 145, 0);
}

.btn{
    padding: 8px 20px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary{
    background-color: var(--contrast);
    color: var(--primary);
    border: 3px solid var(--contrast);
    transition: all 0.1s ease-out;
    mix-blend-mode: multiply;
}

.btn--outline{
    font-size: 1.5rem;
    background-color:transparent;
    color: var(--contrast);
    padding: 4px 10px;
    border: 3px solid var(--contrast);
    transition: all 0.1s ease-out;
    mix-blend-mode: multiply;
}

.btn-medium{
    padding: 8px 20px;
    font-size: 20px;
}

.btn--large{
    padding:12px 26px;
    font-size: 20px;
}

.btn--medium:hover, .btn--large:hover{
    background: var(--highlight);
    color: var(--contrast);
    transition: all 0.1s ease-out;
}
.workField{
    background: linear-gradient(120deg, rgb(122, 61, 20)0%, rgb(63, 63, 63) 100%);
    width:100%;
    background: url(/images/bg_orange.jpg) center center/cover no-repeat;
    padding: 40px 0 40px;
    margin-bottom: 4px;
}

.workField_Container{
    width:75%;
    margin: 0 auto;
}

.workField_List{
    display: grid;
    grid-template-columns: 40% 60%;
    list-style: none;
}

.workField_Description,
.workField_Grid_Container{
    text-align: center;
    border:2px black;
}

.workField_Description{
    color: var(--primary);
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.644);
    margin: auto;
    flex: 1;
    font-size:30px;
}
.workField_Title{
    color: var(--primary);
    font-size:80px;
    text-shadow: 0 4px 10px rgba(0, 0, 0, 0.644);
}
.workField_Button{
    margin-top: 30px;
    padding: 5px 20px;
    border-radius:20px;
    background: none;
    border:5px solid var(--primary);
    font-size:20px;
    color: var(--primary);
    cursor: pointer;
}

.workField_Grid{
    height: 100%;
    display: grid;
    grid-template-columns: auto auto;
}
.workField_Grid_Item{
    flex:1;
    width:90%;
    height:200px;
    margin: 10px;
    background: rgb(255, 215, 84);
    border: 1px solid black;
    border-radius:20px;
}


/* MOBILE */
@media only screen and (max-width:1200px){
    .workField_List{
        display: grid;
        grid-template-columns: 100%;
        list-style: none;
    }
}


/*DEBUG*/
/* .workField_Grid_Container:hover,
.workField_Grid_Item:hover{
    background: rgb(209, 238, 135);
} */
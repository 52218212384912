html{
    scroll-behavior:smooth;
}

video{
    object-fit:cover;
    filter:brightness(1.2) grayscale(0.8);
    width:100%;
    height:100%;
    position:fixed;
    z-index:-1;
}

.hero-container{
    /*background: url('/images/bg_ice.png') center center/cover no-repeat;*/
    background-attachment: fixed;
    height:100vh;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba (0,0,0,0.2);
    object-fit:contain;
}

.name-card{
    text-align: center;
}

.name-card > h1{
    color:rgb(255, 255, 255);
    font-size:100px;
    margin-top:-100px;
    padding-left:30px;
    padding-right:30px;
    background-color:rgb(0, 0, 0);
    mix-blend-mode: multiply;
}
.name-card > h2{
    color:rgb(0, 0, 0);
    font-size:50px;
    padding-left:40px;
    padding-right:40px;
    background-image: linear-gradient(to right,rgb(0, 255, 200),rgb(179, 255, 0));
    mix-blend-mode: multiply;
}

.hero-container > p{
    margin-top:8px;
    color: rgb(26, 26, 26);
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin-top:32px;
}

.hero-btns .btn{
    margin: 6px;
}

.fa-play-circle{
    margin-left:4px;
}

@media screen and (max-width: 960px){
    .hero-container > h1{
        font-size:70px;
        margin-top:-150px;
    }
}

@media screen and (max-width: 768px){
    .hero-container > h1{
        font-size:50px;
        margin-top:-100px;
    }

    .hero-container > p{
        font-size:30px;
    }

    .name-card > h1{
        font-size:60px;
    }   
    .name-card > h2{
        font-size:30px;
    }

    .btn-mobile{
        display: block;
        text-decoration:none;
    }

    .btn{
        width:100%;
    }
}
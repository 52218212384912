.GridElement{
    flex:1;
}

.GridElement_link{
    display: flex;
    flex-direction: column;
    margin: auto;
}

.GridElement_pic_wrapper{
    width: 100%;
    margin: auto;
    flex:1
  }

.GridElement_pic{
    width:100%;
    height:100%;
}

.GridElement_description{
    background: white;
    flex:1;
    
}
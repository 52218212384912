.cards {
  padding: 2rem 4rem;
  background: rgb(255, 255, 255);
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.336);
}

.cards__title{
  width: 100%;
  margin: 1em auto 1.5em;
  font-size:1.5rem;
  text-shadow: 0 5px 7px rgba(12, 18, 29, 0.3);
}

h1 {
  text-align: center;
}

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  max-width: 1120px;
  width: 90%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 50px 0 45px;
}


@media only screen and (min-width: 1200px) {
  .content__blog__container {
    width: 84%;
  }
}

@media only screen and (min-width: 1024px) {
  .cards__items {
    display: flex;
  }
}